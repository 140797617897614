<template>
  <div v-if="item">
    <v-row>
      <v-col cols="12" sm="6">
        <vx-card-list title="Basic info" :item="item" :tab="0">
          <v-list-item>
            <v-list-item-title> Id </v-list-item-title>
            <v-list-item-subtitle class="cursor-pointer info--text" @click="$copy(item._id)">
              {{ item._id }}
            </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title> Username </v-list-item-title>
            <v-list-item-subtitle class="cursor-pointer info--text" @click="$copy(item.username)"
              >{{ item.username }}
            </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title> First name </v-list-item-title>
            <v-list-item-subtitle> {{ item.firstName }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title> Last name </v-list-item-title>
            <v-list-item-subtitle> {{ item.lastName }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title> User type </v-list-item-title>
            <v-list-item-subtitle> {{ item.userType | capitalize }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title> Intention user type </v-list-item-title>
            <v-list-item-subtitle> {{ item.intentionUserType | capitalize }} </v-list-item-subtitle>
          </v-list-item>
          <v-list-item>
            <v-list-item-title> Share personal details </v-list-item-title>
            <v-list-item-subtitle> {{ item?.preferences?.isContactPublic ? 'Yes' : 'No' }} </v-list-item-subtitle>
          </v-list-item>
        </vx-card-list>

        <vx-card-list-meta :item="item" />

        <vx-card-list title="Contact" :item="item" :tab="0">
          <v-list-item>
            <v-list-item-title> Email </v-list-item-title>
            <v-list-item-subtitle class="cursor-pointer info--text" @click="$copy(item.email)">
              {{ item.email }}
            </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title> Phone </v-list-item-title>
            <v-list-item-subtitle class="cursor-pointer info--text" @click="$copy(item.phone)">
              {{ item.phone }}
            </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title> Contact Pref</v-list-item-title>
            <v-list-item-subtitle class="d-flex">
              <div v-for="(value, name) in item.contactPref" :key="name" class="mr-2">
                {{ name }}: {{ value ? 'Yes' : 'No' }}&nbsp;
              </div>
            </v-list-item-subtitle>
          </v-list-item>
        </vx-card-list>

        <v-card class="mb-4">
          <v-card-title class="primary--text">
            Photo
            <v-spacer />
            <vx-btn color="primary" icon :to="$toEdit('user', item._id, 0)">
              <v-icon>mdi-square-edit-outline</v-icon>
            </vx-btn>
          </v-card-title>

          <v-card-text>
            <vx-img width="200px" auto :src="$getUserImage(item, '400')" />
          </v-card-text>
        </v-card>

        <vx-card-list title="Stats" :item="item" :tab="0">
          <v-list-item>
            <v-list-item-title> Last logged in </v-list-item-title>
            <v-list-item-subtitle v-if="item.stats"> {{ item.stats.lastLoggedIn | dateTime }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title> Signed up date </v-list-item-title>
            <v-list-item-subtitle> {{ item.createdAt | dateTime }} </v-list-item-subtitle>
          </v-list-item>
        </vx-card-list>
      </v-col>
      <v-col cols="12" sm="6">
        <vx-card-list title="References" :item="item" hide-edit>
          <v-list-item :to="$toList('invitation', { email: item.email })">
            <v-list-item-title> Invitation </v-list-item-title>
            <v-list-item-subtitle> {{ count.onboardingRequests }} </v-list-item-subtitle>
            <v-list-item-icon></v-list-item-icon>
          </v-list-item>

          <v-list-item :to="$toList('account', { 'users.user': item._id })">
            <v-list-item-title> Profiles </v-list-item-title>
            <v-list-item-subtitle> {{ count.accounts }} </v-list-item-subtitle>
            <v-list-item-icon></v-list-item-icon>
          </v-list-item>

          <v-list-item :to="$toList('voucher', { userId: item._id })">
            <v-list-item-title> Vouchers </v-list-item-title>
            <v-list-item-subtitle>{{ count.vouchers }} </v-list-item-subtitle>

            <v-list-item-icon @click.prevent="dialog = true">
              <v-icon>mdi-plus</v-icon>
            </v-list-item-icon>
          </v-list-item>

          <v-list-item :to="$toList('location', { createdBy: item._id })">
            <v-list-item-title> Locations </v-list-item-title>
            <v-list-item-subtitle>{{ count.locations }} </v-list-item-subtitle>
            <v-list-item-icon></v-list-item-icon>
          </v-list-item>

          <v-list-item :to="$toList('space', { createdBy: item._id })">
            <v-list-item-title> Spaces </v-list-item-title>
            <v-list-item-subtitle>{{ count.spaces }} </v-list-item-subtitle>
            <v-list-item-icon></v-list-item-icon>
          </v-list-item>

          <v-list-item :to="$toList('booking', { createdBy: item._id })">
            <v-list-item-title> Bookings created </v-list-item-title>
            <v-list-item-subtitle>{{ count.bookings }} </v-list-item-subtitle>
            <v-list-item-icon></v-list-item-icon>
          </v-list-item>

          <v-list-item :to="$toList('message', { createdBy: item._id })">
            <v-list-item-title> Messages </v-list-item-title>
            <v-list-item-subtitle>{{ count.messages }} </v-list-item-subtitle>
            <v-list-item-icon></v-list-item-icon>
          </v-list-item>

          <v-list-item :to="$toList('review', { createdBy: item._id })">
            <v-list-item-title> Reviews </v-list-item-title>
            <v-list-item-subtitle>{{ count.reviews }} </v-list-item-subtitle>
            <v-list-item-icon></v-list-item-icon>
          </v-list-item>
        </vx-card-list>

        <vx-card-list v-if="(item.secondaryEmails || []).length" title="Secondary Emails" :item="item" :tab="0">
          <v-list v-for="(info, index) in item.secondaryEmails" :key="index" dense>
            <v-list-item>
              <v-list-item-title> Name </v-list-item-title>
              <v-list-item-subtitle> {{ info.firstName }} {{ info.lastName }} </v-list-item-subtitle>
            </v-list-item>

            <v-list-item>
              <v-list-item-title> Email </v-list-item-title>
              <v-list-item-subtitle> {{ info.email }} </v-list-item-subtitle>
            </v-list-item>
            <v-list-item>
              <v-list-item-title> Notification </v-list-item-title>
              <v-list-item-subtitle class="d-flex">
                <div v-for="(value, name) in info.notification" :key="name" class="mr-2">
                  {{ name }}: {{ value ? 'Yes' : 'No' }}&nbsp;
                </div>
              </v-list-item-subtitle>
            </v-list-item>
            <v-divider v-if="index !== item.secondaryEmails.length - 1" />
          </v-list>
        </vx-card-list>

        <vx-card-list v-if="(item.secondaryPhones || []).length" title="Secondary Phones" :item="item" :tab="0">
          <v-list v-for="(info, index) in item.secondaryPhones" :key="index" dense>
            <v-list-item>
              <v-list-item-title> Name </v-list-item-title>
              <v-list-item-subtitle> {{ info.firstName }} {{ info.lastName }} </v-list-item-subtitle>
            </v-list-item>

            <v-list-item>
              <v-list-item-title> Phone </v-list-item-title>
              <v-list-item-subtitle> {{ info.phone }} </v-list-item-subtitle>
            </v-list-item>
            <v-list-item>
              <v-list-item-title> Notification </v-list-item-title>
              <v-list-item-subtitle class="d-flex">
                <div v-for="(value, name) in info.notification" :key="name" class="mr-2">
                  {{ name }}: {{ value ? 'Yes' : 'No' }}&nbsp;
                </div>
              </v-list-item-subtitle>
            </v-list-item>
            <v-divider v-if="index !== item.secondaryPhones.length - 1" />
          </v-list>
        </vx-card-list>
      </v-col>

      <v-col cols="12">
        <vx-card-json :item="item" />
      </v-col>
    </v-row>

    <AddVoucher v-if="dialog" v-model="dialog" :account="item" @resolve="getCount()" />
  </div>
</template>

<script>
import { UserService } from '@tutti/services';
import AddVoucher from '@admin/dialogs/add-voucher';

export default {
  components: {
    AddVoucher,
  },

  data() {
    return {
      dialog: false,
      item: null,
      count: {},
    };
  },
  created() {
    this.getUser();
    this.getCount();
  },
  methods: {
    async getUser() {
      const response = await UserService.getByIdAdmin(this.$route.params.id);
      if (response) {
        this.item = response.data;
        this.$setTitle(this.item.displayName);
      }
    },
    async getCount() {
      const response = await UserService.count(this.$route.params.id);
      if (response) {
        this.count = response.data;
      }
    },
  },
};
</script>
