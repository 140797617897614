var render = function () {
  var _vm$item, _vm$item$preferences;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.item ? _c('div', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-card-list', {
    attrs: {
      "title": "Basic info",
      "item": _vm.item,
      "tab": 0
    }
  }, [_c('v-list-item', [_c('v-list-item-title', [_vm._v(" Id ")]), _c('v-list-item-subtitle', {
    staticClass: "cursor-pointer info--text",
    on: {
      "click": function click($event) {
        return _vm.$copy(_vm.item._id);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.item._id) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Username ")]), _c('v-list-item-subtitle', {
    staticClass: "cursor-pointer info--text",
    on: {
      "click": function click($event) {
        return _vm.$copy(_vm.item.username);
      }
    }
  }, [_vm._v(_vm._s(_vm.item.username) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" First name ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.item.firstName) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Last name ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.item.lastName) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" User type ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm._f("capitalize")(_vm.item.userType)) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Intention user type ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm._f("capitalize")(_vm.item.intentionUserType)) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Share personal details ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s((_vm$item = _vm.item) !== null && _vm$item !== void 0 && (_vm$item$preferences = _vm$item.preferences) !== null && _vm$item$preferences !== void 0 && _vm$item$preferences.isContactPublic ? 'Yes' : 'No') + " ")])], 1)], 1), _c('vx-card-list-meta', {
    attrs: {
      "item": _vm.item
    }
  }), _c('vx-card-list', {
    attrs: {
      "title": "Contact",
      "item": _vm.item,
      "tab": 0
    }
  }, [_c('v-list-item', [_c('v-list-item-title', [_vm._v(" Email ")]), _c('v-list-item-subtitle', {
    staticClass: "cursor-pointer info--text",
    on: {
      "click": function click($event) {
        return _vm.$copy(_vm.item.email);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.item.email) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Phone ")]), _c('v-list-item-subtitle', {
    staticClass: "cursor-pointer info--text",
    on: {
      "click": function click($event) {
        return _vm.$copy(_vm.item.phone);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.item.phone) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Contact Pref")]), _c('v-list-item-subtitle', {
    staticClass: "d-flex"
  }, _vm._l(_vm.item.contactPref, function (value, name) {
    return _c('div', {
      key: name,
      staticClass: "mr-2"
    }, [_vm._v(" " + _vm._s(name) + ": " + _vm._s(value ? 'Yes' : 'No') + "  ")]);
  }), 0)], 1)], 1), _c('v-card', {
    staticClass: "mb-4"
  }, [_c('v-card-title', {
    staticClass: "primary--text"
  }, [_vm._v(" Photo "), _c('v-spacer'), _c('vx-btn', {
    attrs: {
      "color": "primary",
      "icon": "",
      "to": _vm.$toEdit('user', _vm.item._id, 0)
    }
  }, [_c('v-icon', [_vm._v("mdi-square-edit-outline")])], 1)], 1), _c('v-card-text', [_c('vx-img', {
    attrs: {
      "width": "200px",
      "auto": "",
      "src": _vm.$getUserImage(_vm.item, '400')
    }
  })], 1)], 1), _c('vx-card-list', {
    attrs: {
      "title": "Stats",
      "item": _vm.item,
      "tab": 0
    }
  }, [_c('v-list-item', [_c('v-list-item-title', [_vm._v(" Last logged in ")]), _vm.item.stats ? _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm._f("dateTime")(_vm.item.stats.lastLoggedIn)) + " ")]) : _vm._e()], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Signed up date ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm._f("dateTime")(_vm.item.createdAt)) + " ")])], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-card-list', {
    attrs: {
      "title": "References",
      "item": _vm.item,
      "hide-edit": ""
    }
  }, [_c('v-list-item', {
    attrs: {
      "to": _vm.$toList('invitation', {
        email: _vm.item.email
      })
    }
  }, [_c('v-list-item-title', [_vm._v(" Invitation ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.count.onboardingRequests) + " ")]), _c('v-list-item-icon')], 1), _c('v-list-item', {
    attrs: {
      "to": _vm.$toList('account', {
        'users.user': _vm.item._id
      })
    }
  }, [_c('v-list-item-title', [_vm._v(" Profiles ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.count.accounts) + " ")]), _c('v-list-item-icon')], 1), _c('v-list-item', {
    attrs: {
      "to": _vm.$toList('voucher', {
        userId: _vm.item._id
      })
    }
  }, [_c('v-list-item-title', [_vm._v(" Vouchers ")]), _c('v-list-item-subtitle', [_vm._v(_vm._s(_vm.count.vouchers) + " ")]), _c('v-list-item-icon', {
    on: {
      "click": function click($event) {
        $event.preventDefault();
        _vm.dialog = true;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-plus")])], 1)], 1), _c('v-list-item', {
    attrs: {
      "to": _vm.$toList('location', {
        createdBy: _vm.item._id
      })
    }
  }, [_c('v-list-item-title', [_vm._v(" Locations ")]), _c('v-list-item-subtitle', [_vm._v(_vm._s(_vm.count.locations) + " ")]), _c('v-list-item-icon')], 1), _c('v-list-item', {
    attrs: {
      "to": _vm.$toList('space', {
        createdBy: _vm.item._id
      })
    }
  }, [_c('v-list-item-title', [_vm._v(" Spaces ")]), _c('v-list-item-subtitle', [_vm._v(_vm._s(_vm.count.spaces) + " ")]), _c('v-list-item-icon')], 1), _c('v-list-item', {
    attrs: {
      "to": _vm.$toList('booking', {
        createdBy: _vm.item._id
      })
    }
  }, [_c('v-list-item-title', [_vm._v(" Bookings created ")]), _c('v-list-item-subtitle', [_vm._v(_vm._s(_vm.count.bookings) + " ")]), _c('v-list-item-icon')], 1), _c('v-list-item', {
    attrs: {
      "to": _vm.$toList('message', {
        createdBy: _vm.item._id
      })
    }
  }, [_c('v-list-item-title', [_vm._v(" Messages ")]), _c('v-list-item-subtitle', [_vm._v(_vm._s(_vm.count.messages) + " ")]), _c('v-list-item-icon')], 1), _c('v-list-item', {
    attrs: {
      "to": _vm.$toList('review', {
        createdBy: _vm.item._id
      })
    }
  }, [_c('v-list-item-title', [_vm._v(" Reviews ")]), _c('v-list-item-subtitle', [_vm._v(_vm._s(_vm.count.reviews) + " ")]), _c('v-list-item-icon')], 1)], 1), (_vm.item.secondaryEmails || []).length ? _c('vx-card-list', {
    attrs: {
      "title": "Secondary Emails",
      "item": _vm.item,
      "tab": 0
    }
  }, _vm._l(_vm.item.secondaryEmails, function (info, index) {
    return _c('v-list', {
      key: index,
      attrs: {
        "dense": ""
      }
    }, [_c('v-list-item', [_c('v-list-item-title', [_vm._v(" Name ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(info.firstName) + " " + _vm._s(info.lastName) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Email ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(info.email) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Notification ")]), _c('v-list-item-subtitle', {
      staticClass: "d-flex"
    }, _vm._l(info.notification, function (value, name) {
      return _c('div', {
        key: name,
        staticClass: "mr-2"
      }, [_vm._v(" " + _vm._s(name) + ": " + _vm._s(value ? 'Yes' : 'No') + "  ")]);
    }), 0)], 1), index !== _vm.item.secondaryEmails.length - 1 ? _c('v-divider') : _vm._e()], 1);
  }), 1) : _vm._e(), (_vm.item.secondaryPhones || []).length ? _c('vx-card-list', {
    attrs: {
      "title": "Secondary Phones",
      "item": _vm.item,
      "tab": 0
    }
  }, _vm._l(_vm.item.secondaryPhones, function (info, index) {
    return _c('v-list', {
      key: index,
      attrs: {
        "dense": ""
      }
    }, [_c('v-list-item', [_c('v-list-item-title', [_vm._v(" Name ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(info.firstName) + " " + _vm._s(info.lastName) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Phone ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(info.phone) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Notification ")]), _c('v-list-item-subtitle', {
      staticClass: "d-flex"
    }, _vm._l(info.notification, function (value, name) {
      return _c('div', {
        key: name,
        staticClass: "mr-2"
      }, [_vm._v(" " + _vm._s(name) + ": " + _vm._s(value ? 'Yes' : 'No') + "  ")]);
    }), 0)], 1), index !== _vm.item.secondaryPhones.length - 1 ? _c('v-divider') : _vm._e()], 1);
  }), 1) : _vm._e()], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('vx-card-json', {
    attrs: {
      "item": _vm.item
    }
  })], 1)], 1), _vm.dialog ? _c('AddVoucher', {
    attrs: {
      "account": _vm.item
    },
    on: {
      "resolve": function resolve($event) {
        return _vm.getCount();
      }
    },
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }) : _vm._e()], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }